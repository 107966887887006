/**
 * Main entry point
 */
import React, { useState, useEffect, memo } from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Redirect, Switch, useLocation, useHistory } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import configureStore from "./core/configureStore";
import ReducerRegistry from "./core/ReducerRegistry";
import { coreReducers } from "./core";
import { SET_USER_SESSION, AutoLogoutTimer, SESSION_STORAGE_KEY, NonAuthenticatedAccount, ResetPassword, STATE_KEY as USER_STATE_KEY } from "./core/User";
import { Main } from "./core/App";
import { DataUseCertification } from "./core/Agreement";
import { loadState, saveState } from "./storage";
import { useAnalytics, useSession } from "./hooks";
import { ScrollToTop } from "./utilities";
import { AbilityContext, ability, subjects } from "./ability";
import version from "./version.json";
import "./index.scss";
import "./core/assets/icomoon/style.css";
import "./core/assets/fontastic/styles.css";

// This Wrapper is ultimately used to setup the Redux
// Provider & store so we can use hooks that depend on redux
// in the <Index /> component
const IndexWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState(null);

  useEffect(() => {
    // Redux setup
    const reducerRegistry = new ReducerRegistry(coreReducers);
    const store = configureStore(reducerRegistry);
    setStore(store);

    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? null : (
        <React.StrictMode>
          <Provider store={store}>
            <AbilityContext.Provider value={ability}>
              <BrowserRouter>
                <ScrollToTop />
                <Index />
              </BrowserRouter>
            </AbilityContext.Provider>
          </Provider>
        </React.StrictMode>
      )}
    </>
  );
};

const Index = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [postAuthenticatedRedirectURL, setPostAuthenticatedRedirectURL] = useState(null);
  const user = useSelector(state => state[USER_STATE_KEY]);
  const isUserAuthenticated = user.userName ? true : false;
  const isAgreementSignatureRequired = user.requiredSignature;
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { initSession } = useSession();
  const { pathname } = useLocation();
  const history = useHistory();

  // This code allows the application to correctly redirect to the requested URL
  // once the user has been authenticated
  useEffect(() => {
    if (isUserAuthenticated && postAuthenticatedRedirectURL && !isAgreementSignatureRequired) {
      const url = postAuthenticatedRedirectURL;
      setPostAuthenticatedRedirectURL(null);

      history.push(url);
    }
  });

  //console.log(`in <Index />, isUserAuthenticated: ${isUserAuthenticated}, location: ${location.pathname}, postAuthenticatedRedirectURL: ${postAuthenticatedRedirectURL}, isAgreementSignatureRequired: ${isAgreementSignatureRequired}`);
  //console.log("inside of <Index />, IMV4", ability.can("view", subjects.APP_IMV4));

  useEffect(() => {
    // Greet user with a console message showing version information
    console.log("%cORIEN" + "%cAvatar", "background-color:white;font-size:42px;color:#25205c;font-family:Montserrat-Bold;padding:5px 0 5px 5px", "background-color:white;font-size:42px;color:#25205c;font-family:Montserrat;padding:5px 5px 5px 0");
    console.table(version);

    trackEvent("Application", "Launched", JSON.stringify(version));
  }, []);

  useEffect(() => {
    initSession();

    setIsLoading(false);

    if (!isUserAuthenticated && pathname != "/account/login") {
      setPostAuthenticatedRedirectURL(pathname);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Switch>
          {!isUserAuthenticated ? (
            <>
              <Route path="/account" component={NonAuthenticatedAccount} />
              <Redirect to="/account" />
            </>
          ) : (
            <>
              {isAgreementSignatureRequired ? (
                <>
                  <DataUseCertification />
                </>
              ) : (
                <>
                  <AutoLogoutTimer />
                  <Redirect from="/account/login" to="/" />
                  <Route exact path="/account/resetpassword" component={ResetPassword} />
                  <Route path="/" component={Main} />
                </>
              )}
            </>
          )}
        </Switch>
      )}
    </>
  );
};

// Mount the application
render(<IndexWrapper />, document.getElementById("app"));
