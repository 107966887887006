// Persist the state to sessionStorage at the given key.
export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch (error) {
    //
  }
};

// Attempts to get and return the state from sessionStorage
// at a given key.
export const loadState = (key) => {
  try {
    const serializedState = sessionStorage.getItem(key);

    if(serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);

  } catch (error) {
    return undefined;
  }
};

// Deletes a key from sessionStorage
export const removeState = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    //
  }
};
